// Conteúdo página
.conta-content {
  padding: 20px;
  border: 1px solid #ddd;

  // MOBILE
  @include mq(s) {
    padding: 10px;
  }
}

// Tabela
.table-user {
  float: left;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 15px;

  thead {
    border-bottom: 2px solid #ddd;

    // MOBILE
    @include mq(s) {
      //display: none;
    }
  }

  tr {
    border-bottom: 1px solid #ddd;

    // MOBILE
    @include mq(s) {
      display: block;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      background: #fff;
      border: none;
      margin-bottom: 20px;
      padding: 10px 15px;
    }
  }

  th,
  td {
    text-align: left;
    padding: 15px 10px;
    vertical-align: middle;

    &.center {
      // TABLET
      @include mq(m) {
        text-align: center;
      }

      // DESKTOP
      @include mq(l) {
        text-align: center;
      }
    }

    &.opcoes {
      // MOBILE
      @include mq(s) {
        margin-top: 10px;
        text-align: center !important;
      }
      .btn-sm {
        padding: 10px 15px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;

        i {
          margin-right: 5px;
        }
      }
    }
  }

  th {
    color: $cor_fonte_escura;
    font-weight: bold;
    font-size: 16px;
  }

  td {
    position: relative;

    // MOBILE
    @include mq(s) {
      display: block;
      width: 100% !important;
      padding: 5px 0;
    }

    // Título de cada coluna (Mobile)
    &::before {
      content: attr(data-label);
      display: none;
      color: $cor_fonte_escura;

      // MOBILE
      @include mq(s) {
        display: block;
      }
    }
  }

  small {
    font-size: 13px;
  }

  b {
    font-weight: bold;
    color: $cor_fonte_escura;
  }

  // Opções
  .forum-table-options {
    .btn {
      margin: 5px;

      i {
        margin-right: 5px;
      }
    }
  }
}

.segura-data-documento,
.segura-categoria-documento {
  // MOBILE
  @include mq(s) {
    float: left;
    margin-bottom: 5px;
    width: 100%;
  }
}
