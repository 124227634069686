// Banner do topo
.institucional-banner {
  float: left;
  width: 100%;
  height: 200px;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  // TABLET
  @include mq(m) {
    height: 150px;
  }

  // MOBILE
  @include mq(s) {
    height: 100px;
  }
}
// Título do topo
.institucional-titulo {
  float: left;
  width: 100%;
  height: 140px;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  background-attachment: fixed;

  // TABLET
  @include mq(m) {
    height: 120px;
  }

  // MOBILE
  @include mq(s) {
    height: 80px;
  }

  .titulo {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {
  // DESKTOP
  @include mq(l) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

// Cliente bloco item
.cliente-item {
  display: block;
  margin: 5px 10px;
  border: 1px solid rgba(#000, 0.1);
  border-radius: 8px;
  overflow: hidden;

  // Imagem
  img {
    transition: 0.3s ease-out;
  }

  &:hover img {
    position: relative;
    z-index: 2;
    transform: scale(1.1);
  }
}
